

.sidebar {
  max-width: 240px;
  height: 140vh;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:45px;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-item-icon {
  margin-right: 8px;
}

.sidebar-item-text {
  width: 100%;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
    color: #3949ab;
    font-weight: bold;
}
